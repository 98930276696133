import React from 'react';
import dynamic from 'next/dynamic';

import CompanySection from './CompanySection';
import CompanySectionTitle from './CompanySectionTitle';
import CompanyFeature from './CompanyFeature';
import FadeUp from './FadeUp';
import LPAnimationFontLoader from './LPAnimationFontLoader';

const AutomationAnimation = dynamic(() => import('./AutomationAnimation'), {
  ssr: false,
});

const IntegrationAnimation = dynamic(() => import('./IntegrationAnimation'), {
  ssr: false,
});

const TemplatesAnimation = dynamic(() => import('./TemplatesAnimation'), {
  ssr: false,
});

const MoreAnimation = dynamic(() => import('./MoreAnimation'), {
  ssr: false,
});

export default function CompaniesFeaturesSection() {
  return (
    <CompanySection>
      <LPAnimationFontLoader />
      <div className="companies-features-section">
        <FadeUp>
          <CompanySectionTitle style={{ maxWidth: '900px', margin: '0 auto' }}>
            Faça o que você faz de melhor, deixe que o videomatik faça o resto
          </CompanySectionTitle>
          <h2 className="subheader">Transforme dados em vídeos personalizados em minutos.</h2>
          <div className="features">
            <CompanyFeature
              content={(
                <TemplatesAnimation />
              )}
              title="Automação de Conteúdo"
              description="Faça edições de vídeo e áudio automaticamente e termine seu trabalho que duraria horas em minutos"
            />
            <CompanyFeature
              content={(
                <AutomationAnimation />
              )}
              title="Videos personalizados em escala"
              description="Crie uma experiência em vídeo personalizada e veja seu engajamento aumentar"
            />
            <CompanyFeature
              content={(
                <IntegrationAnimation />
              )}
              title="API"
              description="Utilize nossa API para acelerar o desenvolvimento de software e permitir que seus usuários criem e editem vídeos"
            />
            <CompanyFeature
              content={(
                <MoreAnimation />
              )}
              title="Muito +"
              description="Com o Videomatik você cria soluções personalizadas para melhorar seu engajamento através de vídeos. Integração com planilhas, upload para plataformas de vídeo, webhooks personalizados e muito mais. "
            />
          </div>
        </FadeUp>
      </div>
      <style jsx>
        {`
        .companies-features-section {
          text-align: center;

          .subheader {
            font-size: 1.5rem;
            font-weight: 400;
            margin-top: 1.2em;
            margin-bottom: 1.5em;
          }

          .features {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4rem;
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 3rem;

            @media screen and (max-width: 1024px) {
              max-width: 700px;
              grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width: 768px) {
              max-width: 380px;
              grid-template-columns: 1fr;
            }
          }
        }
        `}
      </style>
    </CompanySection>
  );
}
