import React from 'react';
import PropTypes from 'prop-types';

export default function CompanyFeature({
  image,
  imageAlt,
  title,
  description,
  content,
}) {
  return (
    <div className="company-feature">
      <img src={image} alt={imageAlt} className="image" />
      {content && (
        <div className="content">
          { content }
        </div>
      )}
      <h3 className="title">
        { title }
      </h3>
      <p className="description">
        { description }
      </p>
      <style jsx>
        {`
        .company-feature {
          display: inline-flex;
          flex-direction: column;
          text-align: left;
          font-size: 16px;

          > .image, > .content {
            max-width: 100%;
            // TODO: Remove when all images are replaced by images
            // border-radius: var(--box-border-radius);
            border-radius: 28px;
            overflow: hidden;
          }

          > .title {
            font-size: 1.3em;
            font-weight: 700;
            margin-top: 1.5rem;
            margin-bottom: 0.75rem;
          }
        }
        `}
      </style>
    </div>
  );
}

CompanyFeature.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
};
