import React from 'react';

const fontsToLoad = [
  {
    name: 'HankRnd-Light',
    family: 'HankRnd',
    weight: 300,
    cssStyle: 'normal',
  },
  {
    name: 'HankRnd-Regular',
    family: 'HankRnd',
    weight: 400,
    cssStyle: 'normal',
  },
  {
    name: 'HankRnd-Bold',
    family: 'HankRnd',
    weight: 700,
    cssStyle: 'normal',
  },
  {
    name: 'HankRnd-Black',
    family: 'HankRnd',
    weight: 900,
    cssStyle: 'normal',
  },
];

const LPAnimationFontLoader = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      visibility: 'hidden',
      lineHeight: 0,
    }}
  >
    {fontsToLoad.map((font) => (
      <span
        key={font.name}
        id={`font-element-${font.name}`}
        style={{
          fontFamily: font.family,
          fontWeight: font.weight,
          fontStyle: font.cssStyle,
        }}
      >
        { font.name }
      </span>
    ))}
    <span />
  </div>
);

export default LPAnimationFontLoader;
